import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import NotFound from '@/views/NotFound.vue'

/** Import module routes */
import { authRoutes } from '@/modules/auth/routes'
import { networkRoutes } from '@/modules/network/routes'
import { stakingRoutes } from '@/modules/staking/routes'
import { walletRoutes } from '@/modules/wallet/routes'
import { helpRoutes } from '@/modules/help/routes'
import { migrateRoutes } from '@/modules/migrate/routes'
import { validatorsRoutes } from '@/modules/validators/routes'

/** Guards */

Vue.use(VueRouter)

/** Define default routes */
const routes: RouteConfig[] = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

/** Define redirects */
const redirects: RouteConfig[] = [
  {
    path: '/',
    redirect: { name: 'wallet' }
  }
]

/** Create router instance and export */
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /** Public routes */
    ...routes,
    ...authRoutes,
    {
      /** Authenticated routes */
      path: '/',
      component: () => import(/* webpackChunkName: "authenticated-routes" */ '@/layouts/AuthenticatedLayout.vue'),
      children: [
        ...networkRoutes,
        ...stakingRoutes,
        ...walletRoutes,
        ...validatorsRoutes,
        ...helpRoutes,
        ...migrateRoutes,
        ...redirects
      ]
    }
  ]
})

export default router
