import { RouteConfig } from 'vue-router'

export const walletRoutes:RouteConfig[] = [
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "wallet" */ './Wallet.vue')
  },
  {
    path: '/wallet/withdraw',
    name: 'walletWithdraw',
    component: () => import(/* webpackChunkName: "wallet" */ './Withdraw.vue')
  },
  {
    path: '/wallet/withdraw/success',
    name: 'walletWithdrawSuccess',
    component: () => import(/* webpackChunkName: "wallet" */ './WithdrawSuccess.vue')
  },
  {
    path: '/wallet/rewards',
    name: 'walletRewards',
    beforeEnter (to, from, next) {
      if (localStorage.getItem('stakingContractPaused') === 'true') {
        next({ name: 'staking' })
      } else {
        next()
      }
    },
    component: () => import(/* webpackChunkName: "wallet" */ './Rewards.vue')
  },
  {
    path: '/wallet/rewards/success',
    name: 'walletRewardsSuccess',
    component: () => import(/* webpackChunkName: "wallet" */ './RewardsSuccess.vue')
  }
]
