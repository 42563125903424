import { VARIABLES } from '@/variables'
import MAPPING_RINKEBY from '@/services/mapping-rinkeby.json'
import MAPPING_MAINNET from '@/services/mapping-mainnet.json'

export class ValidatorNames {
  validatorMapping: any = {}
  constructor () {
    if (VARIABLES.NETWORK_ID === 4) {
      this.validatorMapping = this.formatKeys(MAPPING_RINKEBY)
    } else if (VARIABLES.NETWORK_ID === 1) {
      this.validatorMapping = this.formatKeys(MAPPING_MAINNET)
    }
  }

  formatKeys (mapping: any) {
    const formattedObj: any = {}
    Object.keys(mapping).forEach((key: string) => {
      formattedObj[key.toLowerCase()] = mapping[key]
    })
    return formattedObj
  }
}
