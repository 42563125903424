
export const VARIABLES: any = {}
if (process && process.env && process.env.VUE_APP_NETWORK === 'rinkeby') {
  VARIABLES.ETHERSCAN_API_URL = 'https://api-rinkeby.etherscan.io'
  VARIABLES.ETHERSCAN_URL = 'https://rinkeby.etherscan.io'
  VARIABLES.ETHERSCAN_API_KEY = 'KKH8472U614IPUVJI4UARRTVW3RKZT2FG1'
  VARIABLES.THE_GRAPH_URL = 'https://api.studio.thegraph.com/query/27787/cudo-graph/v0.0.4'
  VARIABLES.ONBOARD_APPID = '088a48aa-9e7a-49a4-8e80-aacaf09c3049'
  VARIABLES.NETWORK_ID = 4
  VARIABLES.STAKING_REWARD_CONTRACT_ADDRESS = { 4: '0xc2A6f2227238F117Be6bc25d241dEad4C4639C83' }
  VARIABLES.MAPPING_CONTRACT_ADDRESS = { 4: '0x8C7C5bB1d3518a97Bc2E901ca6898Eed0863842F' }
  VARIABLES.CUDOS_TOKEN_RECOVERY = { 4: '0x5b0EF146c47C970524166da839EFf08004CD5895' }
} else {
  VARIABLES.ETHERSCAN_API_URL = 'https://api.etherscan.io'
  VARIABLES.ETHERSCAN_URL = 'https://etherscan.io'
  VARIABLES.ETHERSCAN_API_KEY = 'KKH8472U614IPUVJI4UARRTVW3RKZT2FG1'
  VARIABLES.THE_GRAPH_URL = 'https://api.studio.thegraph.com/query/4247/cudos-wallet/v0.0.2'
  VARIABLES.ONBOARD_APPID = '34bd6f99-f910-46ef-bc1c-c38a214ba9c8'
  VARIABLES.NETWORK_ID = 1
  VARIABLES.STAKING_REWARD_CONTRACT_ADDRESS = { 4: '0xc2A6f2227238F117Be6bc25d241dEad4C4639C83', 1: '0xac50c843c539fe2831dd73f13c189bd2452a5e65' }
  VARIABLES.MAPPING_CONTRACT_ADDRESS = { 1: '0x4c7c45e0AfC8c3e2bD0dE263A716167162b92416' }
  VARIABLES.CUDOS_TOKEN_RECOVERY = { 1: '0x78C97024e98A6Eb7dc12bdE7d304f4bdbF567364' }
}
