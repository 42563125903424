import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/assets/scss/main.scss'
import { createProvider } from './vue-apollo'
import { substring, splitAmount, formatGasPrice } from './plugins/format'
import { GasPrices } from '@/services/gas-prices'

Vue.config.productionTip = false

Vue.prototype.$utils = { substring, splitAmount, formatGasPrice }
Vue.prototype.$services = { gasPrices: new GasPrices() }

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
