import { RouteConfig } from 'vue-router'

export const stakingRoutes:RouteConfig[] = [
  {
    path: '/staking',
    name: 'staking',
    component: () => import(/* webpackChunkName: "staking" */ './Staking.vue')
  },
  {
    path: '/staking/stake',
    name: 'stake',
    beforeEnter (to, from, next) {
      if (localStorage.getItem('stakingContractPaused') === 'true') {
        next({ name: 'staking' })
      } else {
        next()
      }
    },
    component: () => import(/* webpackChunkName: "staking" */ './Stake.vue')
  },
  {
    path: '/staking/stake/withdraw',
    name: 'stakingWithdraw',
    beforeEnter (to, from, next) {
      if (localStorage.getItem('stakingContractPaused') === 'true') {
        next({ name: 'staking' })
      } else {
        next()
      }
    },
    component: () => import(/* webpackChunkName: "staking" */ './StakingWithdraw.vue')
  },
  {
    path: '/stake/withdraw-exit-validator',
    name: 'stakingWithdrawExitValidator',
    component: () => import(/* webpackChunkName: "staking" */ './StakingWithdrawExitValidator.vue')
  },
  {
    path: '/stake/withdraw-exit-validator/success',
    name: 'stakingWithdrawExitValidatorSuccess',
    component: () => import(/* webpackChunkName: "staking" */ './StakingWithdrawExitValidatorSuccess.vue')
  },
  {
    path: '/staking/stake/withdraw/confirm',
    name: 'stakingWithdrawConfirm',
    component: () => import(/* webpackChunkName: "staking" */ './StakingWithdrawConfirm.vue')
  },
  {
    path: '/staking/stake/withdraw/success',
    name: 'stakingWithdrawSuccess',
    component: () => import(/* webpackChunkName: "staking" */ './StakingWithdrawSuccess.vue')
  },
  {
    path: '/staking/stake/confirm',
    name: 'stakeConfirm',
    component: () => import(/* webpackChunkName: "staking" */ './StakeConfirm.vue')
  },
  {
    path: '/staking/stake/success',
    name: 'stakeSuccess',
    component: () => import(/* webpackChunkName: "staking" */ './StakeSuccess.vue')
  },
  {
    path: '/wallet/rewards',
    name: 'walletRewards',
    component: () => import(/* webpackChunkName: "wallet" */ '../wallet/Rewards.vue')
  }
]
