import { VARIABLES } from '../variables'

export class GasPrices {
  url = `${VARIABLES.ETHERSCAN_API_URL}/api?module=gastracker&action=gasoracle&apikey=${VARIABLES.ETHERSCAN_API_KEY}`

  cachedValue: any = null

  async getAverageGasPrice () {
    if (this.cachedValue != null) {
      return this.cachedValue
    } else if (process && process.env && process.env.VUE_APP_NETWORK === 'rinkeby') {
      this.cachedValue = this.calculateRinkebyGasPrice()
    } else {
      this.cachedValue = await this.calculateMainnetGasPrice()
    }
    return this.cachedValue
  }

  async calculateMainnetGasPrice (): Promise<number> {
    const response = await fetch(this.url)
    const result = await response.json()
    const proposedGasPrice = (result.result || {}).ProposeGasPrice
    const suggestedGasPrice = ((result.result || {}).suggestBaseFee)
    return parseFloat(proposedGasPrice) + parseFloat(suggestedGasPrice || proposedGasPrice) // suggestedGasPrice is currently not supported on rinkeby
  }

  calculateRinkebyGasPrice (): number {
    return 1.5
  }
}
