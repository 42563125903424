import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true
})
export class AuthModule extends VuexModule {
  token: string | null = null

  @Mutation
  setToken (token: string): void {
    this.token = token
  }

  @Action
  getToken (token: string): void {
    this.context.commit('setToken', token)
  }
}
