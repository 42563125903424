import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
  namespaced: true
})
export class ValidatorsModule extends VuexModule {
  isValidator = false
  wasValidator = false
  validatorExitedWithdrawAmount = 9999999

  @Mutation
  setIsValidator (value: boolean) : void {
    this.isValidator = value
  }

  @Mutation
  setWasValidator (value: boolean) : void {
    this.wasValidator = value
  }

  @Mutation
  setvalidatorExitedWithdrawAmount (value: number) : void {
    this.validatorExitedWithdrawAmount = value
  }
}
