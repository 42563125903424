import { RouteConfig } from 'vue-router'
import { ensureNotValidator, ensureValidator, ensureWasValidator } from '@/router/guards'
export const validatorsRoutes: RouteConfig[] = [
  {
    path: '/validators',
    name: 'validators',
    component: () => import(/* webpackChunkName: "validators" */ './Validators.vue')
    // beforeEnter: ensureValidator
  },
  {
    path: '/validators/withdrawal',
    name: 'validatorsWithdrawal',
    component: () => import(/* webpackChunkName: "validators" */ './ValidatorWithdrawal.vue')
    // beforeEnter: ensureValidator
  },
  {
    path: '/validators/exit',
    name: 'validatorsExit',
    component: () => import(/* webpackChunkName: "validators" */ './ValidatorExit.vue')
    // beforeEnter: ensureValidator
  },
  {
    path: '/validators/exit/confirmed',
    name: 'validatorsExitConfirmed',
    component: () => import(/* webpackChunkName: "validators" */ './ValidatorExitConfirmed.vue')
    // beforeEnter: ensureValidator
  },
  {
    path: '/validators/become-a-validator',
    name: 'validatorsBecomeAValidator',
    component: () => import(/* webpackChunkName: "validators" */ './BecomeAValidator.vue')
    // beforeEnter: ensureNotValidator
  },
  {
    path: '/validators/become-a-validator/success',
    name: 'validatorsBecomeAValidatorSuccess',
    component: () => import(/* webpackChunkName: "validators" */ './BecomeAValidatorSuccess.vue')
    // beforeEnter: ensureNotValidator
  },
  {
    path: '/validators/increase-stake/success',
    name: 'validatorsIncreaseStakeSuccess',
    component: () => import(/* webpackChunkName: "validators" */ './IncreaseStakeSuccess.vue')
    // beforeEnter: ensureNotValidator
  },
  {
    path: '/validators/request-for-validator',
    name: 'validatorsRequestToBecomeAValidator',
    component: () => import(/* webpackChunkName: "validators" */ './RequestToBecomeAValidator.vue')
    // beforeEnter: ensureNotValidator
  },
  {
    path: '/validators/request-for-validator/success',
    name: 'requestToBecomeAValidatorSuccess',
    component: () => import(/* webpackChunkName: "validators" */ './RequestToBecomeAValidatorSuccess.vue')
    // beforeEnter: ensureNotValidator
  },
  {
    path: '/validators/exited',
    name: 'validatorExited',
    component: () => import(/* webpackChunkName: "validators" */ './ValidatorExited.vue')
    // beforeEnter: ensureWasValidator
  }
]
