import { RouteConfig } from 'vue-router'

export const migrateRoutes:RouteConfig[] = [

  {
    path: '/migrate',
    name: 'migrate',
    // component: () => import(/* webpackChunkName: "migrate" */ './MigrateHome.vue')
    component: () => import(/* webpackChunkName: "migrate" */ './MigrateUnavailable.vue')
  },
  {
    path: '/migrate/install-plugin',
    name: 'migrateInstall',
    // component: () => import(/* webpackChunkName: "migrate" */ './Migrate.vue')
    component: () => import(/* webpackChunkName: "migrate" */ './MigrateUnavailable.vue')
  },
  {
    path: '/migrate/form',
    name: 'migrateForm',
    // component: () => import(/* webpackChunkName: "migrate" */ './MigrateForm.vue')
    component: () => import(/* webpackChunkName: "migrate" */ './MigrateUnavailable.vue')
  },
  {
    path: '/migrate/migrating',
    name: 'migratingAccounts',
    beforeEnter (to, from, next) {
      if (from.name !== 'migrateForm') {
        next({ name: 'migrate' })
      } else {
        next()
      }
    },
    // component: () => import(/* webpackChunkName: "migrate" */ './MigratingAccounts.vue')
    component: () => import(/* webpackChunkName: "migrate" */ './MigrateUnavailable.vue')
  },
  {
    path: '/migrate/success',
    name: 'migrationSuccess',
    beforeEnter (to, from, next) {
      if (from.name !== 'migratingAccounts') {
        next({ name: 'migrate' })
      } else {
        next()
      }
    },
    // component: () => import(/* webpackChunkName: "migrate" */ './MigrationSuccess.vue')
    component: () => import(/* webpackChunkName: "migrate" */ './MigrateUnavailable.vue')
  },
  {
    path: '/migrate/failure',
    name: 'migrationFailure',
    beforeEnter (to, from, next) {
      if (from.name !== 'migratingAccounts') {
        next({ name: 'migrate' })
      } else {
        next()
      }
    },
    // component: () => import(/* webpackChunkName: "migrate" */ './MigrationFailure.vue')
    component: () => import(/* webpackChunkName: "migrate" */ './MigrateUnavailable.vue')
  }
]
