import Vue from 'vue'
import Vuex from 'vuex'
import { vuexLocal } from './persist'
import { AuthModule } from '@/modules/auth/store'
import { ValidatorsModule } from '@/modules/validators/store'
import { WalletModule } from '@/modules/wallet/store'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    auth: AuthModule,
    validators: ValidatorsModule,
    wallet: WalletModule
  }
})
