import { BigNumber, ethers } from 'ethers'

export function substring (value: string, end = 8, ellipsis = true, start = 0): string | null {
  if (!value) return null
  const text = value.substring(start, end)
  return ellipsis && value.length > end
    ? text + '...'
    : text
}

export function formatGasPrice (fee: BigNumber, gasPrice: any): string {
  const gas = fee.mul(BigNumber.from(parseInt(gasPrice)))
  return ethers.utils.formatUnits(gas, 9)
}

export function splitAmount (amount: string, style: string | null, isMobile = false): string {
  const n = amount.split('.')

  if (n.length === 2 && n[1] === '0') {
    return `<span class="${style}">${n[0]}</span>`
  }
  if (isMobile) {
    try {
      n[1] = parseFloat('0.' + n[1]).toFixed(2).split('.')[1]
    } catch (e) {
      // if something goes wrong just trim the remainder
      n[1] = n[1].slice(0, 2)
    }
  }
  if (n.length > 1) {
    return style
      ? `<span class="${style}">${n[0]}<span style="opacity:0.5" class="subtitle-2 ${style}">.${n[1]}</span>
        </span>`
      : `<span>${n[0]}</span><span style="opacity:0.5;" class="subtitle-2 ${style}">.${n[1]}</span>`
  } else {
    return style
      ? `<span class="${style}">${n[0]}</span>`
      : `<span>${n[0]}</span>`
  }
}
