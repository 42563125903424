






import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  name: 'App'
})
export default class App extends Vue {
  @State('loaded', { namespace: 'wallet' }) loaded!: boolean

  // If intialisation has been successfull will set loading state to false
  // Loading state's across the board need to improved, handle across the board
  // in a more consistent way. Utilisation of a shared loading component would also be nice.
  created (): void {
    this.$store.dispatch('wallet/bootstrapWeb3', this.$apollo.provider.defaultClient)
  }
}
