import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import MigrateIcon from '@/assets/migrate-logo.vue'
import KeplrLogo from '@/assets/keplr-logo.vue'
import WarningIcon from '@/assets/warning-icon.vue'
import ValidatorIcon from '@/assets/validator-icon.vue'
import NetworkIcon from '@/assets/network-icon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#52A6F8',
        secondary: '#222E4F',
        accent: '#82B1FF',
        error: '#F45353',
        info: '#2196F3',
        success: '#2e8540',
        warning: '#FFC107',
        'toolbar-color': '#F7F9FE'
      }
    }
  },
  icons: {
    values: {
      migrate: { // name of our custom icon
        component: MigrateIcon // our custom component
      },
      keplr: {
        component: KeplrLogo
      },
      warningIcon: {
        component: WarningIcon
      },
      validator: {
        component: ValidatorIcon
      },
      network: {
        component: NetworkIcon
      }
    }
  }
})
