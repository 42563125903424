import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import router from '@/router/'
import { BigNumber, ethers } from 'ethers'
import Onboard from 'bnc-onboard'
import ServiceProviderMetadata from '../../smart-contract-metadata/ServiceProvider.json'
import StakingRewardMetadata from '../../smart-contract-metadata/StakingReward.json'
import CudosTokenMetadata from '../../smart-contract-metadata/CudosToken.json'
import AddressMapperMetadata from '../../smart-contract-metadata/AddressMapper.json'
import CudosTokenRecoveryMetadata from '../../smart-contract-metadata/CudosTokenRecovery.json'
import { gql } from '@apollo/client'
import { Network } from '@ethersproject/networks'
import { VARIABLES } from '@/variables'
import { ValidatorNames } from '@/services/validator-names'

declare global {
  interface Window {
    ethereum: any,
    keplr: any,
    getOfflineSigner: any
  }
}

export class StakingReward {
  minRequiredStakingAmountForServiceProviders: string
  maxStakingAmountForServiceProviders: string
  minServiceProviderFee: string

  constructor (minRequiredStakingAmountForServiceProviders: string, maxStakingAmountForServiceProviders: string, minServiceProviderFee: string) {
    this.minRequiredStakingAmountForServiceProviders = minRequiredStakingAmountForServiceProviders
    this.maxStakingAmountForServiceProviders = maxStakingAmountForServiceProviders
    this.minServiceProviderFee = minServiceProviderFee
  }
}

class Validators {
  static sortValidators (validators: Promise<any[]>): Promise<any> {
    return validators.then((results: any[]) => {
      return results.sort((a: any, b: any): number => {
        const aExited = a.exited || !a.isServiceProviderActive
        const bExited = b.exited || !b.isServiceProviderActive
        if (aExited && bExited) {
          return a.id.localeCompare(b.id)
        } else if (aExited && !bExited) {
          return 1
        } else if (!aExited && bExited) {
          return -1
        } else {
          return a.id.localeCompare(b.id)
        }
      })
    })
  }
}

// A lot of this store can be broken out and modularised.
export class Programme {
  index: any
  minStakingLengthInBlocks: any
  allocPoint: any
  lastRewardBlock: any
  accTokensPerShare: any
  totalStaked: any
}

export class Delegation {
  delegator: any
  serviceProvider: any
  delegatedStake: any
  withdrawalRequested: any
  withdrawalRequestAmount: any
  withdrawalPermittedFrom: any
}

export class PendingReward {
  serviceProvider: any
  reward: any
}

@Module({
  namespaced: true
})
export class WalletModule extends VuexModule {
  validatorNames = new ValidatorNames()
  account: string | null = null
  accountCudos: string | null = null
  isKeplrSignedIn = false
  mappedCudosAddress: string | null = null
  transactionGasUsed: ethers.BigNumber = ethers.BigNumber.from('0')
  hashTransaction: string | null = null
  changeAccount = false
  pendingRewardsFromServiceProvider: ethers.BigNumber = ethers.BigNumber.from('0')
  contracts: any
  userStakeData = {
    totalUserStake: null
  }

  userCudosBalance: number | null = null
  userTokenRecover: BigNumber = BigNumber.from(0)
  userDelegatedStake: string | null = null
  userStake: number | null = null
  isStakingPaused = false
  recentTransactions = []
  loaded = false
  validators: Promise<any[]> = Promise.resolve([])
  fakeValidators = ['0x9ff72b92a2A310Ee089A7901a6E2A59E162728c0']
  provider: ethers.providers.Web3Provider | null = null
  chainId: Network | null = null
  apolloClient: any
  programmes: Promise<Programme[]> = Promise.resolve([])
  delegations: Promise<Delegation[]> = Promise.resolve([])
  pendingRewards: Promise<PendingReward[]> = Promise.resolve([])
  stakingReward: Promise<StakingReward> = Promise.resolve(new StakingReward('', '', ''))
  serviceProviderContracts: any = []
  addressMapperContracts: any = []
  tokenRecoveryContracts: any = []

  get numberOfBlocksInADay (): number {
    return 6500
  }

  get alladdressMapperContracts (): any {
    return this.addressMapperContracts
  }

  get allTokenRecoveryContracts (): any {
    return this.tokenRecoveryContracts
  }

  get allServiceProviderContracts (): any {
    return this.serviceProviderContracts
  }

  @Action
  async isAccountWhitelistedValidator (): Promise<boolean> {
    // Need to fetch
    return this.context.getters.allValidators.then((validators: any[]) => {
      return validators.some((validator: any) => {
        return validator.serviceProviderManager === this.context.getters.userAccount
      })
    })
  }

  get userValidatorObject (): Promise<any | undefined> {
    return this.context.getters.allValidators.then((validators: any[]) => {
      return validators.filter((validator: any) => {
        return validator.serviceProviderManager === this.context.getters.userAccount
      }).pop()
    })
  }

  get allContracts (): any {
    return this.contracts
  }

  get newUserStake (): number | null {
    return this.userStake
  }

  get getUserTokenRecover (): BigNumber {
    return this.userTokenRecover
  }

  get stakingPaused (): boolean | null {
    return this.isStakingPaused
  }

  get keplrSignedIn (): boolean {
    return this.isKeplrSignedIn
  }

  get cudosBalance (): number | null {
    return this.userCudosBalance
  }

  get delegatedStakeOfUser (): string | null {
    return this.userDelegatedStake
  }

  get userAccount (): string | null {
    return this.account
  }

  get cudosAccount (): string | null {
    return this.accountCudos
  }

  get mappedCudos (): string | null {
    return this.mappedCudosAddress
  }

  get usedGas (): BigNumber {
    return this.transactionGasUsed
  }

  get deprecatedPendingRewards (): string | null {
    return ethers.utils.formatEther(this.pendingRewardsFromServiceProvider)
  }

  get allActiveValidators (): Promise<any> {
    const validators = Promise.all([this.validators, this.context.getters.userValidatorObject])
      .then((results: any[]) => {
        return results[0].filter((val: any) => {
          return (val.exited || val.isServiceProviderActive) && (!results[1] || !results[1].id || val.id !== results[1].id)
        })
      })
    return Validators.sortValidators(validators)
  }

  get allActiveValidatorsWithSelf (): Promise<any> {
    const validators = Promise.all([this.validators])
      .then((results: any[]) => {
        return results[0].filter((val: any) => {
          return val.exited || val.isServiceProviderActive
        })
      })
    return Validators.sortValidators(validators)
  }

  get allValidators (): Promise<any> {
    return Validators.sortValidators(this.validators)
  }

  get allProgrammes (): Promise<Programme[]> {
    return this.programmes
  }

  get allPendingRewards (): Promise<PendingReward[]> {
    return this.pendingRewards
  }

  get stakingRewardParams (): Promise<StakingReward> {
    return this.stakingReward
  }

  get programmesPerIndex (): Promise<any> {
    return this.programmes.then((programmes: Programme[]) => {
      const indexed: any = {}
      programmes.forEach((programme: Programme) => {
        indexed[programme.index.toString()] = programme
      })
      return indexed
    })
  }

  get delegationsPerValidator (): Promise<any> {
    return this.delegations.then((delegations: Delegation[]) => {
      const indexed: any = {}
      delegations.forEach((delegation: Delegation) => {
        indexed[delegation.serviceProvider] = delegation
      })
      return indexed
    })
  }

  get allDelegations (): Promise<Delegation[]> {
    return this.delegations
  }

  get blockNumber (): Promise<number> {
    if (!this.provider) {
      return Promise.resolve(0)
    }
    return this.provider.getBlockNumber()
  }

  get accountChange (): boolean {
    return this.changeAccount
  }

  get transactionHash (): string | null {
    return this.hashTransaction
  }

  @Mutation
  setProgrammes (programmes: Promise<Programme[]>) {
    this.programmes = programmes
  }

  @Mutation
  setDelegations (delegations: Promise<Delegation[]>) {
    this.delegations = delegations
  }

  @Mutation
  setProvider (provider: ethers.providers.Web3Provider) {
    this.provider = provider
  }

  @Mutation
  setValidators (validators: Promise<any[]>) {
    this.validators = validators
  }

  @Mutation
  setUserTokenRecover (tokenAmount: BigNumber) {
    this.userTokenRecover = tokenAmount
  }

  @Mutation
  setRecentTransactions (transactions: any) {
    this.recentTransactions = transactions
  }

  @Mutation
  setApolloClient (apolloClient: any) {
    this.apolloClient = apolloClient
  }

  @Mutation
  async setContracts (contracts: any): Promise<void> {
    this.contracts = contracts
    // This needs to be moved when a structure to organise the fetching of users data
    // has been decided upon.
  }

  @Mutation
  setAccount (account: string): void {
    this.account = account
  }

  @Mutation
  setCudosAccount (account: string): void {
    this.accountCudos = account
  }

  @Mutation
  setIsKeplrSignedIn (isSignedIn: boolean): void {
    this.isKeplrSignedIn = isSignedIn
  }

  @Mutation
  setMappedCudosAccount (account: string): void {
    this.mappedCudosAddress = account
  }

  @Mutation
  setGasUsed (gas: BigNumber): void {
    this.transactionGasUsed = gas
  }

  @Mutation
  setTransactionHash (hash: string): void {
    this.hashTransaction = hash
  }

  @Mutation
  setUserDelegatedStake (stake: string): void {
    this.userDelegatedStake = stake
  }

  @Mutation
  setUserCudosBalance (balance: number): void {
    this.userCudosBalance = balance
  }

  @Mutation
  setUserStake (stake: number): void {
    this.userStake = stake
  }

  @Mutation
  setIsStakingPaused (isPaused: boolean): void {
    this.isStakingPaused = isPaused
  }

  @Mutation
  setUserStakeData (stake: any): void {
    this.userStakeData = stake
  }

  @Mutation
  setLoaded (loaded: boolean): void {
    this.loaded = loaded
  }

  @Mutation
  setChangeAccount (changeAccount: boolean): void {
    this.changeAccount = changeAccount
  }

  @Action({ rawError: true })
  async fetchRecentTransactions (): Promise<void> {
    const response = await fetch(`${VARIABLES.ETHERSCAN_API_URL}/api?module=account&action=tokentx&address=${this.account}&contractaddress=${this.contracts.cudosToken.address}&sort=desc&apiKey=${VARIABLES.ETHERSCAN_API_KEY}`)
    const result = await response.json()
    this.context.commit('setRecentTransactions', result.result)
  }

  @Action({ rawError: true })
  connectToServiceProvider (contractAddress: string) {
    if (!this.serviceProviderContracts[contractAddress]) {
      const signer = this.provider?.getSigner()
      const serviceProviderContract = new ethers.Contract(
        contractAddress,
        ServiceProviderMetadata.abi,
        signer
      )
      this.serviceProviderContracts[contractAddress] = serviceProviderContract
    }
    return this.serviceProviderContracts[contractAddress]
  }

  @Action({ rawError: true })
  connectToAddressMapper (contractAddress: string) {
    if (!this.addressMapperContracts[contractAddress]) {
      const signer = this.provider?.getSigner()
      const addressMappingContract = new ethers.Contract(
        contractAddress,
        // @ts-ignore
        AddressMapperMetadata.abi,
        signer
      )
      this.addressMapperContracts[contractAddress] = addressMappingContract
    }
    // @ts-ignore
    return this.addressMapperContracts[contractAddress]
  }

  @Action({ rawError: true })
  connectToCudosTokenRecovery (contractAddress: string) {
    if (!this.tokenRecoveryContracts[contractAddress]) {
      const signer = this.provider?.getSigner()
      const cudosTokenRecoveryContract = new ethers.Contract(
        contractAddress,
        // @ts-ignore
        CudosTokenMetadata.abi,
        signer
      )
      this.tokenRecoveryContracts[contractAddress] = cudosTokenRecoveryContract
    }
    // @ts-ignore
    return this.tokenRecoveryContracts[contractAddress]
  }

  @Action({ rawError: true })
  async bootstrapWeb3 (apolloClient: any): Promise<void> {
    this.context.commit('setApolloClient', apolloClient)
    const previouslySelectedWallet = localStorage.getItem('selectedWallet')
    const previouslySelectedNetwork = localStorage.getItem('selectedNetwork')

    // const previouslySelectedAddress = localStorage.getItem('selectedAddress')
    let walletName
    let provider
    const onboard = Onboard({
      dappId: VARIABLES.ONBOARD_APPID,
      hideBranding: true,
      networkId: VARIABLES.NETWORK_ID, // Only supports rinkeby
      darkMode: true,
      subscriptions: {
        address: (address) => {
          localStorage.setItem('selectedAddress', address)
          this.context.commit('setAccount', address)
          if (address) {
            this.context.dispatch('getMappedCudosAccount')
          }
        },
        network: (network) => {
          if (network.toString() !== previouslySelectedNetwork) {
            localStorage.setItem('selectedNetwork', network.toString())
            if (previouslySelectedNetwork) {
              window.location.replace('/wallet')
            }
          }
        },
        wallet: (wallet) => {
          provider = new ethers.providers.Web3Provider(wallet.provider)
          this.context.commit('setProvider', provider)
          walletName = wallet.name
        }
      },
      walletSelect: {
        wallets: [
          {
            walletName: 'metamask',
            preferred: true
          }
          // TODO: Add Portis support
          // { walletName: 'portis' }
        ]
      }
    })

    let userSelectedAWallet

    if (this.changeAccount) {
      await window.ethereum.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {}
          }
        ]
      })
    }

    if (
      previouslySelectedWallet !== null &&
      previouslySelectedWallet !== 'null'
    ) {
      userSelectedAWallet = await onboard.walletSelect(
        previouslySelectedWallet
      )
    } else {
      userSelectedAWallet = await onboard.walletSelect()
    }

    if (userSelectedAWallet) {
      // Check wallet is ready to transact
      const readyToTransact = await onboard.walletCheck()

      if (readyToTransact) {
        const onboardState = onboard.getState()

        // store the selected wallet name to be retrieved next time the app loads
        // @ts-ignore
        localStorage.setItem('selectedWallet', walletName)

        this.context.commit('setAccount', onboardState.address)

        // @ts-ignore
        this.chain = await provider.getNetwork()

        // @ts-ignore
        const signer = provider.getSigner()

        const stakingRewardContract = new ethers.Contract(
          // @ts-ignore
          VARIABLES.STAKING_REWARD_CONTRACT_ADDRESS[this.chain.chainId.toString()],
          StakingRewardMetadata.abi,
          signer
        )

        const addressMappingContract = new ethers.Contract(
          // @ts-ignore
          VARIABLES.MAPPING_CONTRACT_ADDRESS[this.chain.chainId.toString()],
          // @ts-ignore
          AddressMapperMetadata.abi,
          signer
        )

        const cudosTokenRecoveryContract = new ethers.Contract(
          // @ts-ignore
          VARIABLES.CUDOS_TOKEN_RECOVERY[this.chain.chainId.toString()],
          // @ts-ignore
          CudosTokenRecoveryMetadata.abi,
          signer
        )

        const cudosToken = new ethers.Contract(
          await stakingRewardContract.token(),
          CudosTokenMetadata.abi,
          signer
        )

        const contracts = {
          stakingRewardContract,
          cudosToken,
          addressMappingContract,
          cudosTokenRecoveryContract
        }

        this.context.commit('setContracts', contracts)

        const userCudosBalance = await cudosToken.balanceOf(onboardState.address)

        this.context.commit(
          'setUserCudosBalance',
          ethers.utils.formatEther(userCudosBalance)
        )

        this.context.commit('processRawProgrammes', {
          stakingRewardContract: stakingRewardContract,
          context: this.context
        })
        this.context.commit('populateValidators')
        this.context.commit('populateDelegations')
        this.context.commit('populatePendingRewards')

        this.context.commit('setLoaded', true)
      } else {
        window.location.replace('/wallet')
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }]
        })
      }
    }
  }

  @Action
  async mapAccount (): Promise<void> {
    const transaction = await this.contracts.addressMappingContract.setAddress(this.accountCudos)
    router.push('/migrate/migrating')
    const transactionReceipt = await transaction.wait()

    if (transactionReceipt.status !== 1) {
      await router.push('/migrate/failure')
    } else {
      this.context.commit('setTransactionHash', transactionReceipt.transactionHash)
      this.context.commit('setGasUsed', ethers.utils.formatUnits(transactionReceipt.gasUsed._hex, 9))
      await router.push('/migrate/success')
    }
  }

  @Action
  async getRecoverableTokens (): Promise<void> {
    // const amount = BigNumber.from('1000000000000000000') // Only for testing
    // const transaction = await this.contracts.cudosTokenRecoveryContract.setWhitelistedStakers(['0xCB8aFFC8592593E2fD430DaA164c29c80E477e68'], [amount]) // Only for testing
    const transaction = await this.contracts.cudosTokenRecoveryContract.whitelistedStakersAmount(this.userAccount)
    this.context.commit('setUserTokenRecover', transaction)
  }

  @Action
  async withdrawRecoverableTokens (): Promise<void> {
    const transaction = await this.contracts.cudosTokenRecoveryContract.extractTokens()
    const transactionReceipt = await transaction.wait()
    console.log('extract', transactionReceipt)
  }

  @Action({ rawError: true })
  async getMappedCudosAccount (): Promise<void> {
    try {
      const transaction = await this.contracts.addressMappingContract.cudosAddress(this.account)
      this.context.commit('setMappedCudosAccount', transaction)
    } catch {}
  }

  @Action({ rawError: true })
  async getIsStakingContractPaused (): Promise<void> {
    try {
      // *Pause/unpause smart contract - only for testing */
      // const pause = await this.contracts.stakingRewardContract.updateUserActionsPaused(true)
      const transaction = await this.contracts.stakingRewardContract.userActionsPaused()
      localStorage.setItem('stakingContractPaused', JSON.stringify(transaction))
      this.context.commit('setIsStakingPaused', transaction)
    } catch {}
  }

  @Action({ rawError: true })
  async connectKeplrWallet () {
    const previouslySelectedCudosAddress = localStorage.getItem('selectedCudosAddress')
    const config = {
      rpc: 'https://sentry1.gcp-uscentral1.cudos.org:26657',
      rest: 'http://35.232.27.92:1317',
      chainName: 'CudosTestnet-Public',
      chainId: 'cudos-testnet-public-2',
      currencies: [{
        coinDenom: 'CUDOS',
        coinMinimalDenom: 'acudos',
        coinDecimals: 18,
        coinGeckoId: 'cudos'
      }],
      stakeCurrency: {
        coinDenom: 'CUDOS',
        coinMinimalDenom: 'acudos',
        coinDecimals: 18,
        coinGeckoId: 'cudos'
      },
      feeCurrencies: [{
        coinDenom: 'CUDOS',
        coinMinimalDenom: 'acudos',
        coinDecimals: 18,
        coinGeckoId: 'cudos'
      }],
      bip44: { coinType: 118 },
      bech32Config: {
        bech32PrefixAccAddr: 'cudos',
        bech32PrefixAccPub: 'cudospub',
        bech32PrefixValAddr: 'cudosvaloper',
        bech32PrefixValPub: 'cudosvaloperpub',
        bech32PrefixConsAddr: 'cudosvalcons',
        bech32PrefixConsPub: 'cudosvalconspub'
      },
      coinType: 118,
      features: ['stargate', 'ibc-transfer', 'no-legacy-stdTx']
    }
    await window.keplr.experimentalSuggestChain(config)
    const offlineSigner = await window.getOfflineSigner(config.chainId)
    const accounts = await offlineSigner.getAccounts()

    if (accounts[0].address !== previouslySelectedCudosAddress) {
      localStorage.setItem('selectedCudosAddress', accounts[0].address)
      this.context.commit('setCudosAccount', accounts[0].address)
    } else {
      this.context.commit('setCudosAccount', previouslySelectedCudosAddress)
      try {
        await router.push('/migrate/form')
      } catch (error) {}
    }
  }

  @Action
  changeWalletAccount () {
    this.context.commit('setChangeAccount', true)
  }

  @Mutation
  async processRawProgrammes (rawData: any) {
    const prom = rawData.stakingRewardContract.numberOfRewardProgrammes().then((programmesNumber: any) => {
      const numberOfProgrammes = programmesNumber.toNumber()
      const programmes = []
      for (let i = 0; i < numberOfProgrammes; i++) {
        programmes.push(rawData.stakingRewardContract.getRewardProgrammeInfo(i))
      }
      return Promise.all(programmes)
    }).then((results: any[]) => {
      const formattedProgrammes = results.map((rawProgramme: any, index: number) => {
        const programme = new Programme()
        programme.index = index
        programme.accTokensPerShare = rawProgramme.accTokensPerShare
        programme.allocPoint = rawProgramme.allocPoint
        programme.lastRewardBlock = rawProgramme.lastRewardBlock
        programme.minStakingLengthInBlocks = rawProgramme.minStakingLengthInBlocks
        programme.totalStaked = rawProgramme.totalStaked
        return programme
      })
      return formattedProgrammes
    })
    rawData.context.commit('setProgrammes', prom)
  }

  @Mutation
  populateValidators () {
    const VALIDATOR_QUERY = gql`query {
        serviceProviders {
          id
          isServiceProviderActive
          serviceProviderManager
          exited
          rewardsFeePercentage
          totalDelegatedStake
          rewardsProgrammeId
          serviceProviderBond
          withdrawalRequestAmount
          withdrawalPermittedFrom
        }
        stakingRewards {
          id,
          minServiceProviderFee,
          minRequiredStakingAmountForServiceProviders,
          maxStakingAmountForServiceProviders
        }
      }`
    const data = this.apolloClient.query({ query: VALIDATOR_QUERY })
      .then((response: any) => response)

    const validators = data
      .then((resResp: any) => resResp.data.serviceProviders)
      .then((validators: any[]) => {
        validators.forEach((val: any) => {
          val.name = this.validatorNames.validatorMapping[val.serviceProviderManager.toLowerCase()]
        })
        return validators
      })
    const stakingReward = data.then((resResp: any) => {
      if (resResp.data.stakingRewards && resResp.data.stakingRewards.length > 0) {
        return resResp.data.stakingRewards[0]
      } else {
        return undefined
      }
    })
    this.validators = validators
    this.stakingReward = stakingReward
  }

  @Mutation
  populateDelegations () {
    const VALIDATOR_QUERY = gql`query {
      delegations(where:{delegator:"${this.account}"}) {
        id
        delegator
        serviceProvider
        delegatedStake
        withdrawalRequested
        withdrawalRequestAmount
        withdrawalPermittedFrom
      }
    }
    `
    const delegations = this.apolloClient.query({ query: VALIDATOR_QUERY })
      .then((response: any) => response)
      .then((resResp: any) => resResp.data.delegations)
    this.delegations = delegations
  }

  @Mutation
  populatePendingRewards () {
    let delegatedServiceProviders: any[] = []
    const pendingRewards = this.delegations.then((delegations: Delegation[]) => {
      delegatedServiceProviders = [...new Set((delegations).map((d: Delegation) => d.serviceProvider))]
      const pendingRewards: any[] = []
      delegatedServiceProviders.forEach((delegatedProvider: any) => {
        const signer = this.provider?.getSigner()
        const serviceContract = new ethers.Contract(
          delegatedProvider,
          ServiceProviderMetadata.abi,
          signer
        )
        pendingRewards.push(serviceContract.pendingRewards(this.account))
      })
      return Promise.all(pendingRewards)
    }).then((rawOutput: any[]) => {
      return rawOutput.map((output: any, index: number) => {
        const reward = new PendingReward()
        reward.reward = output
        reward.serviceProvider = delegatedServiceProviders[index]
        return reward
      })
    }).then((rewards: PendingReward[]) => {
      return rewards.filter((r: PendingReward) => !r.reward || !r.reward.isZero())
    }).catch((e: any) => {
      console.debug(e)
      return []
    })
    this.pendingRewards = pendingRewards
  }
}
