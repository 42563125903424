import { RouteConfig } from 'vue-router'

const redirects: RouteConfig [] = [
  {
    path: '/auth',
    redirect: { name: 'authSignIn' }
  },
  {
    path: '/auth/login',
    redirect: { name: 'authSignIn' }
  },
  {
    path: '/login',
    redirect: { name: 'authSignIn' }
  },
  {
    path: '/sign-in',
    redirect: { name: 'authSignIn' }
  }
]

export const authRoutes:RouteConfig[] = [
  ...redirects,
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layouts/PublicLayout.vue'),
    children: [
      {
        path: 'sign-in',
        name: 'authSignIn',
        component: () => import(/* webpackChunkName: "auth" */ './SignIn.vue')
      }
    ]
  }
]
